.hero {
    background: url('./img/hero2.jpg') no-repeat center center;
    background-size: cover;
  }
  
  h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 4rem !important;
    color: white;
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  @media screen and (max-width: 992px) {
    section.hero h1 {
        font-size: 3rem;
    }
  }
  
  .aga-color {
    color: #458eff !important;
  }
  
  .footer-consent {
    display: none;
    position: fixed; 
    bottom: 0; 
    background-color: rgba(00, 00, 00, 0.8) !important;; 
    border-top: 2px solid #f00; 
    width: 100%; 
    padding: 10px 0; 
    text-align: center; 
    font-size: 16px; 
    z-index: 1000;
  }
  
  .section-header {
    margin-bottom: 50px;
  }
  
  .section-header .section-title {
    margin-top: 100px;
    font-size: 44px;
    text-transform: uppercase;
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
    margin: 0 0 20px;
  }
  
  .section-header .section-title:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -11px;
    left: 50%;
    margin-left: -12px;
    border: 5px solid #fff;
    border-radius: 20px;
    background: #458eff;
  }
  
  .section-block {
    padding-top: 100px;
  }
  
  .media.service-box .pull-left {
    margin-right: 20px;
  }
  
  .media.service-box {
    margin: 25px 0;
  }
  
  .media.service-box .pull-left > i {
    font-size: 24px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    width: 64px;
    border-radius: 100%;
    color: #458eff;
    box-shadow: inset 0 0 0 1px #d7d7d7;
    -webkit-box-shadow: inset 0 0 0 1px #d7d7d7;
    -webkit-transition: background-color 400ms, background-color 400ms;
    transition: background-color 400ms, background-color 400ms;
    position: relative;
  }
  
  .media.service-box .pull-left > i:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    right: -10px;
    border: 4px solid #fff;
    border-radius: 20px;
    background: #458eff;
  }
  
  .media.service-box:hover .pull-left > i {
    background-image: -webkit-gradient(linear, left top, right top, from(#458eff), to(#2c8cb3));
    background-image: linear-gradient(90deg, #458eff 0%, #2c8cb3 100%);
    color: #fff;
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.8);
    -webkit-box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.8);
  }
  
  hr.style1 {
    border-top: 1px solid #8c8b8b;
  }
  
  .team-member {
    padding: 13px;
    background: #eeeeee;
    border: 1px solid #458eff;
    margin-bottom: 4px;
  }
  
  .team-member .team-img {
    margin: 1px 1px 1px 1px;
  }
  
  .team-member-title {
    text-align: center;
  }
  
  .team-member-title h3 {
    font-size: 1.3rem;
  }
  
  .team-member .team-info {
    padding-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 10px;
  }
  
  .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .social-icons > a {
    display: inline-block;
  }
  
  .social-icons > a > li {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: left;
    color: #fff;
    background: #222534;
    border-radius: 3px;
  }
  
  .smalltitle-border {
    border-bottom: 2px;
    border-color: #000;
  
  }
  
  #triggerCover {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #triggerCover:hover {
    opacity: 0.7;
  }
  
  .modalWindow {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000;
    /* Sit on top */
    padding-top: 60px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
  }
  
  /* Modal Content (Image) */
  .modal-content {
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
  }
  
  .modal-image-content {
    margin: auto;
    display: block;
    max-width: 98%;
  }
  
  /* Add Animation - Zoom in the Modal */
  .modal-content,
  #caption {
    animation-name: zoom;
    animation-duration: 0.2s;
  }
  
  @keyframes zoom {
    from {
        transform: scale(0)
    }
  
    to {
        transform: scale(1)
    }
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
  }
  #logo_container
  {
    width: 250px;
  }
  #logo_image {
    width: 60px;
    height: 60px;
    float:left; /* add this */
    vertical-align: middle;
    /* background-color: aqua; */
  }
  #logo_text {
    margin-left: 60px;
    overflow: hidden; /* if you don't want #second to wrap below #first */
    padding: 0px;            
  }
  #logo_main_text {
    font-size: 32px; 
    font-family: 'Josefin Sans', sans-serif; 
    letter-spacing: 0px; 
    height:100%;
  }
  #logo_sec_text {
    font-size: 20px; 
    margin-top: -12px; 
    font-family: 'Lora', serif; 
    padding-top: 0px;
  }
  
  
  
  
  .navbar-margin-right {
    margin-right: 120px;
  }
  
  .inside-block{
    text-align: center;
    display: inline-block;
  }
  
  .margin-block{
    margin-top:10px; 
    margin-bottom: 30px;
    margin-left: 15px;
  }
  
  h4 {
    margin-top:6px;
  }
  
  .contact-bottom-margin
  {
    margin-bottom: 40px;
  }
  
  
  .text-color-coral
  {
    color: coral;
  }
  
  .block-icon
  {
    font-size: 24px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    width: 64px;
    border-radius: 100%;
    color: #458eff;
    box-shadow: inset 0 0 0 2px #d7d7d7;
    -webkit-box-shadow: inset 0 0 0 2px #d7d7d7;
    -webkit-transition: background-color 400ms, background-color 400ms;
    transition: background-color 400ms, background-color 400ms;
    position: relative;
  }
  