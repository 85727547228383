.no-border {
    border: none !important;  /* Use !important to ensure override of Bootstrap styles */
}

.button-link {
    background: none;
    border: none;
    padding: 0;
    color: #007bff;
    cursor: pointer;
}

.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
}