.footer-margin
{
  margin-top: 40px;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.photo-credits
{
  color: #161616;
  font-size: 8px;
}
